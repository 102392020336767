import { Box, Button, Link, Stack, Typography } from "@mui/material";
import { Link as reactLink } from "react-router-dom";
import { SoftBox } from "./StyledComponents/SoftBox";

type Props = {};

export const ShowsFrontPage = (props: Props) => {
	return (
		<Stack alignItems="center" justifyContent="space-evenly" id="shows">
			{/* <Box
				id="large logo"
				sx={{
					backgroundImage: 'url(images/large_logo.png)',
					backgroundSize: 'cover',
					backgroundPosition: 'center',
					height: '70vh',
					width: '70vh',
					display: 'flex',
				}}></Box> */}
			<Box sx={{ pb: 5, p: 2 }} display="flex" justifyContent="center">
				<Stack>
					<Typography
						variant="h1"
						fontWeight="700"
						justifyContent="center"
						sx={{
							textAlign: "center",
							color: "white",
							display: { xs: "none", md: "flex" },
						}}
					>
						Polarshow 2024
					</Typography>
					<Typography
						variant="h2"
						justifyContent="center"
						fontWeight={500}
						sx={{
							textAlign: "center",
							color: "white",
							display: { md: "none", xs: "flex" },
						}}
					>
						Polarshow 2024
					</Typography>
					<br></br>
					{/*<Typography justifyContent="center" variant="h4" sx={{ textAlign: 'center', color: 'white' }}>
						Revyen trenger din støtte! Gi et bidrag på&nbsp;
						<Link color="inherit" underline="always" href="https://www.spleis.no/project/314514">
							Spleis!
						</Link>
					</Typography> */}
				</Stack>
			</Box>
			<SoftBox p={3}>
				<Typography variant="h3" sx={{ textAlign: "center" }}>
					{/*<Link 
						to="/register"
						component={reactLink}
						underline="always"
						variant="inherit"
						color="inherit"
					> 
						Til Registrering
						<br />
				</Link> */}
				Kjøp billetter <Link href="https://tikkio.com/organizer/11645-">her</Link>
				</Typography>
				{/* Add your list of upcoming shows here */}
				{/* <List sx={{ maxWidth: '600px', margin: '0 auto' }}>
					{[
						{ day: 'Lørdag', date: '12.12.2021', time: '20:00' },
						{ day: 'Søndag', date: '13.12.2021', time: '20:00' },
						{ day: 'Mandag', date: '14.12.2021', time: '20:00' },
					].map((show) => {
						return (
							<Stack>
								<ListItemText primary={show.day} secondary={show.date + ' ' + show.time} />
								<Button>Kjøp biletter</Button>
							</Stack>
						);
					})}
				</List> */}
			</SoftBox>
		</Stack>
	);
};

export default ShowsFrontPage;
