import {
	Box,
	Button,
	Container,
	LinearProgress,
	Paper,
	Stack,
	Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useOrder, { Order } from "./hooks/useOrder";
import SoftBox from "./StyledComponents/SoftBox";
import LocalActivityOutlinedIcon from "@mui/icons-material/LocalActivityOutlined";

type Props = {};

const ProcessPayment = (props: Props) => {
	const { orderId } = useParams();
	const { order, isLoading } = useOrder(orderId);
	//const [paymentProcessing, setPaymentProcessing] = React.useState(true);
	const navigate = useNavigate();

	if (isLoading) return <></>;

	if (!order)
		return (
			<Container>
				<Box>
					<Typography>Order not found</Typography>
				</Box>
			</Container>
		);

	const paymentProcessing = ["INITIATED", "RESERVED"].includes(order!.status);

	return (
		<Container>
			<Stack
				display="flex"
				height="100%"
				alignItems="center"
				justifyContent="center"
				gap={3}
			>
				<SoftBox p={3} maxWidth={600}>
					{["INITIATED", "RESERVED"].includes(order.status) && (
						<Stack>
							<Box
								sx={{ width: "100%" }}
								//display={paymentProcessing ? "block" : "none"}
							>
								<LinearProgress />
							</Box>
							<Typography>
								Vent her mens betalingen blir
								behandlet av Vipps. 


							</Typography>
							<Box>Order status {order?.status}</Box>
						</Stack>
					)}
					{order.status === "CANCELLED" && (
						<Stack>
							Betalingen ble avbrutt. Du har ikke blitt belastet
							for noe beløp.

							<Button
										onClick={() => navigate("/register")}
									>
										Ny registrering
									</Button>
									<Button onClick={() => navigate("/")}>
										Tilbake til hjemmesiden
									</Button>
						</Stack>
					)}
					{order.status === "FAILED" && (
						<Stack>
							Betalingen feilet. Du har ikke blitt belastet for
							noe beløp. 

							<Button
										onClick={() => navigate("/register")}
									>
										Ny registrering
									</Button>
									<Button onClick={() => navigate("/")}>
										Tilbake til hjemmesiden
									</Button>
						</Stack>
					)}
					{order.status === "CAPTURED" && (
						<>
							<Stack gap={3}>
								<Typography variant="h4">
									Registrering fullført.{" "}
								</Typography>
								<Box>
									<Typography variant="body1" color="secondary">
										Du vil motta en SMS med bekreftelse og
										referansenummer.{" "}
									</Typography>
									{/*<Paper
										sx={{
											p: 3,
											m: 3,
											alignContent: 'center',
											alignItems: 'center',
											display: 'flex',
											justifyContent: 'center',
											maxWidth: '200px',
										}}>
										<LocalActivityOutlinedIcon fontSize="large" sx={{ p: 1 }} />
										<Typography variant="h5" textTransform="uppercase" color="primary">
											{order.id.slice(0, 5)}
										</Typography>
									</Paper> 

									<Box sx={{ border: '1', borderBlockColor: 'primary' }}>
										<Typography fontWeight={900}>Viktig!</Typography> Ikke del reransenummeret med
										andre siden dette vil brukes som bevis for kjøp ved inngangen.
									</Box>
									Referansenummer og billettene vil bli sendt til deg på epost.*/}
								</Box>
								<Stack
									direction="row"
									justifyContent="space-between"
								>
									<Button
										onClick={() => navigate("/register")}
									>
										Ny registrering
									</Button>
									<Button onClick={() => navigate("/")}>
										Tilbake til hjemmesiden
									</Button>
								</Stack>
							</Stack>
						</>
					)}
				</SoftBox>
			</Stack>
		</Container>
	);
};

export default ProcessPayment;
