
import Box from '@mui/material/Box';
//import { styled } from '@emotion/styled';
import { styled } from '@mui/material';

export const SoftBox = styled(Box)(({ theme }) => ({
	backgroundColor: theme.palette.mode === "dark" ? 'rgba(0, 0, 0, 0.8)' : 'rgba(255, 255, 255, 0.8)',
	p: 2,
	borderRadius: '1rem',
	boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.2)',
	// Use theme values here
	color: theme.palette.primary.main,
}));

export default SoftBox;
